"use client";

import { Session, User } from "@supabase/supabase-js";
import React, { createContext, useCallback, useEffect, useState, useRef, useMemo } from "react";
import { useQuery } from "@tanstack/react-query";

import useSupabaseBrowser from "utils/supabaseBrowserClient";
import useErrorLogger from "hooks/useErrorLogger";
import { getCurrentUser } from "lib/adminApi";
import { ApiContactRole, User as BAUser } from "types/apiTypes";
import { ERROR_TYPES } from "utils/constants";
import useWorkspaces from "hooks/useWorkspaces";
export interface SessionContextState {
  session?: Session | null;
  user?: User | null;
  isSessionLoading: boolean;
  currentUser?: BAUser;
  updateNewUserPhone: () => Promise<void>;
  peopleDataFetched?: boolean;
}

export const SessionContext = createContext<SessionContextState | null>(null);

const { Provider } = SessionContext;

const SupabaseSessionContextProvider = ({
  children,
  initialSession
}: {
  children: React.ReactNode;
  initialSession: Session | null;
  userWorkspaceIdOverride?: string;
}) => {
  const supabaseClient = useSupabaseBrowser();
  const { logError } = useErrorLogger();
  const [isSessionLoading, setIsLoading] = useState(false);
  const [session, setSession] = useState<Session | null>();
  const [user, setUser] = useState<User | null>();
  const updatedClientRef = useRef(false);

  const { data: peopleData, isFetched: peopleDataFetched } = useQuery({
    queryKey: ["currentUser", user?.id],
    queryFn: () => getCurrentUser(supabaseClient, user?.id || ""),
    enabled: !!user?.id,
    staleTime: Infinity
  });
  const { data: workspacesData } = useWorkspaces(peopleData?.data?.type);

  useEffect(() => {
    if (!session && initialSession) {
      setSession(initialSession);
      setUser(initialSession.user || null);
    }
  }, [session, initialSession]);

  useEffect(() => {
    let mounted = true;
    async function getSession() {
      setIsLoading(true);
      const {
        data: { session },
        error
      } = await supabaseClient.auth.getSession();
      if (mounted) {
        if (error) {
          setIsLoading(false);
          return;
        }

        setSession(session);
        setUser(session?.user || null);
        setIsLoading(false);
      }
      setIsLoading(false);
    }

    getSession();
    return () => {
      mounted = false;
    };
  }, []);

  const finalPeopleData = useMemo(() => {
    if (!peopleData?.data || !workspacesData?.length) return null;
    const roleIds = peopleData?.data?.roles?.map((contactRole: ApiContactRole) => contactRole.role_id);
    return {
      ...peopleData?.data,
      validWorkspaceIds: workspacesData
        ?.map((workspace) =>
          workspace.allowed_user_types.includes(peopleData?.data?.type) || workspace.allowed_user_types.includes("All")
            ? workspace.id
            : null
        )
        .filter(Boolean),
      roleIds,
      isAdmin: roleIds?.find((roleId: string) => `${roleId}` === "40")
    };
  }, [peopleData?.data, workspacesData]);

  // this method is currently only called when showing the quiz loader for a new user added
  // through a quiz, as we need to update the phone number of the user
  const updateNewUserPhone = useCallback(async () => {
    if (
      !user?.id ||
      user?.phone ||
      !user?.user_metadata?.phone ||
      user?.user_metadata?.source !== "Quiz" ||
      updatedClientRef?.current
    ) {
      return;
    }
    const { error } = await supabaseClient.auth.updateUser({
      phone: user?.user_metadata?.phone
    });
    updatedClientRef.current = true;
    if (error) {
      logError({
        error,
        source: "Client Quiz Loading - updateNewUserPhone",
        message: error?.message || "Error updating user phone",
        type: ERROR_TYPES.APP_ERROR,
        url: window.location.href,
        additionalInfo: {
          userPhone: user?.user_metadata?.phone,
          userId: user?.id,
          currentUser: peopleData?.data
        }
      });
      return;
    }
    const userData = await supabaseClient.auth.getUser();
    const updatedUser = userData?.data?.user;
    setUser(updatedUser);
  }, [
    supabaseClient.auth,
    user?.id,
    user?.phone,
    user?.user_metadata?.phone,
    user?.user_metadata?.source,
    peopleData?.data,
    logError
  ]);

  return (
    <Provider
      value={{
        session,
        user,
        isSessionLoading,
        currentUser: finalPeopleData,
        updateNewUserPhone,
        peopleDataFetched
      }}
    >
      {children}
    </Provider>
  );
};

export default SupabaseSessionContextProvider;
